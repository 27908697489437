<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-card title="İşlem Detayı">
          <b-list-group>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                İşlem No
              </div>
              <div>
                {{ payment.id }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                İşlem Tutarı
              </div>
              <div>
                {{ payment.amount | toCurrency }} TL
              </div>
              <div
                v-if="payment.instalment_text"
                class="text-primary font-small-2"
              >
                {{ payment.instalment_text }}
              </div>
              <div
                v-else
                class="text-primary font-small-2"
              >
                {{ (payment.instalment === '0')? 'Tek Çekim' : payment.instalment + ' x Taksit' }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Banka
              </div>
              <div>
                {{ payment.bank }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Banka İşlem No
              </div>
              <div>
                {{ (payment.bank_reference)? payment.bank_reference : '-' }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Banka İşlem Sonucu
              </div>
              <div v-if="payment.bank_message">
                <div
                  v-for="(bank_message,key) in payment.bank_message"
                  :key="key"
                >
                  <div
                    v-html="bank_message"
                  />
                </div>
              </div>
              <div v-else>
                -
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Ip Adresi
              </div>
              <div>
                {{ payment.customer_ip }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Banka Sipariş No
              </div>
              <div>
                {{ (payment.order_id)? payment.order_id : '-' }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                İşlem Sonucu
              </div>
              <div>
                {{ payment.payment_status }}
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="payment.created">
              <div class="text-primary font-weight-bold">
                İşlem Tarihi
              </div>
              <div v-if="payment.modified">
                {{ moment(payment.modified).format('DD.MM.YYYY HH:mm:ss') }}
              </div>
              <div v-else-if="payment.created">
                {{ moment(payment.created).format('DD.MM.YYYY HH:mm:ss') }}
              </div>
              <div v-else>
                -
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                İşlemi Gerçekleştiren
              </div>
              <div>
                <span v-if="payment.username">
                  {{ payment.username }}
                </span>
                <span v-else>
                  {{ payment.customer_user }}
                </span>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Müşteri Bilgisi">
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Firma Ünvanı
              </div>
              <div>{{ customer.company }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Vergi No / Vergi Dairesi
              </div>
              <div>{{ customer.tax_number }} / {{ customer.tax_office }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Telefon
              </div>
              <div>{{ customer.phone }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Adres
              </div>
              <div>{{ customer.address }}</div>
            </b-list-group-item>
          </b-list-group>
          <div class="text-center">
            <b-button
              variant="success"
              class="mt-2"
              :href="downloadURL"
              target="_blank"
              :disabled="payment.result !== true"
            >
              <FeatherIcon icon="PrinterIcon" />
              Makbuz Yazdır
            </b-button>
          </div>
          <div class="text-center">
            <b-button
              v-if="userData.id_customers"
              variant="danger"
              class="mt-2"
              :disabled="disapproval"
              @click="disapprovalModalShow"
            >
              <FeatherIcon icon="AlertCircleIcon" />
              İşlem İtirazı
            </b-button>
          </div>
          <b-alert
            v-if="disapproval && disapproval.id_disapproval_statuses === '1'"
            variant="warning"
            show
            class="ml-2 mr-2 mt-2"
          >
            <div class="alert-body text-center">
              <div>
                <FeatherIcon
                  icon="InfoIcon"
                  size="16"
                />
              </div>
              <p class="lead">
                Ödemeye ait aktif itiraz bildirimleri bulunmaktadır.
              </p>
            </div>
          </b-alert>
          <b-alert
            v-else-if="disapproval && disapproval.id_disapproval_statuses === '2'"
            variant="success"
            show
            class="ml-2 mr-2 mt-2"
          >
            <div class="alert-body text-center">
              <div>
                <FeatherIcon
                  icon="InfoIcon"
                  size="16"
                />
              </div>
              <p class="lead">
                Ödemeye ait onaylanan itiraz bildirimleri bulunmaktadır.
              </p>
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="disapprovalModal.status"
      title="İtiraz Nedeni"
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <p>Ödemeye ait bildireceğiniz itiraz kaydı için sebep veya açıklama belirtmeniz gereklidir.</p>
      <b-form-group
        label="Sebep veya Açıklama"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="disapprovalModal.content"
          placeholder="Sebep veya Açıklama"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="disapprovalModal.status = false"
        >
          İptal
        </b-button>
        <b-button
          variant="success"
          :disabled="disapprovalModal.content === null"
          @click="disapprovalQuestion"
        >
          Kaydet
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BButton, BAlert, BModal, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'View',
  components: {
    BFormTextarea,
    BFormGroup,
    BAlert,
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
    BModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      disapprovalModal: {
        status: false,
        content: null,
      },
    }
  },
  computed: {
    payment() {
      return this.$store.getters['paymentsHistory/getPayment_history']
    },
    customer() {
      return this.$store.getters['customers/dataItem']
    },
    disapproval() {
      return this.$store.getters['disapprovalPayments/dataItem']
    },
    downloadURL() {
      const urlParams = [
        `&id=${this.payment.id}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/pdf/payments?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('paymentsHistory/payment_historyView', this.$route.params.id)
        .then(response => {
          this.getCustomer(response.id_customers)
          this.getDisapproval()
        })
    },
    getDisapproval() {
      this.$store.dispatch('disapprovalPayments/getDisapproval', this.$route.params.id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
    disapprovalModalShow() {
      this.disapprovalModal.content = null
      this.disapprovalModal.status = true
    },
    disapprovalQuestion() {
      this.disapprovalModal.status = false
      this.$swal({
        title: 'İşlem İtirazı',
        text: 'İşlemi itiraz etmek istediğinize emin misiniz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('disapprovalPayments/saveData', {
            id_payments: this.$route.params.id,
            id_disapproval_statuses: 1,
            content: this.disapprovalModal.content,
          })
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı',
                  text: 'İşlem itirazı bildirimi yapıldı.',
                  confirmButtonText: 'Kapat',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız',
                  text: 'İşlem itirazı bildirimi yapılamadı.',
                  confirmButtonText: 'Anladım',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
              this.getDisapproval()
            })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
